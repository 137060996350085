import "./libs";
import "./countries-data";
import "./validate-form";
import "./utils";
import "./modal-signup-newsletter";
import "./resize-captcha";
import "./rtl-check";
import "./macfix";
import "./our-services";
import "./spotlight-carousel";
import "./employees";
import "./forms";
import "./our-services";
import "./remove-scroll";
import "./awards-and-recognition";
import "./khs";
import "./facility-box";
import "./careers";
import "./modal-careers-apply-now";
import "./cookie";
import "./contact-us";